* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* Colors */
  --clr__background01: rgb(195, 189, 254);
  --clr__background02: rgb(101, 97, 164);
  --clr__background03: rgb(185, 181, 248);
  --clr__accent: yellow;

  --clr__light: rgb(255, 255, 255);
  --clr__paragraph: rgb(0, 0, 0);

  /* Typography */

  --ff__head01: "Vollkorn", serif;
  --ff__head02: "Bree Serif", serif;
  --ff__head03: "Supermercado One", cursive;
  --ff__bread01: "Kumbh Sans", sans-serif;
  --ff__bread02: "Kumbh Sans", sans-serif;

  --ff__surprise01: "Patua One", cursive;

  --fs__small01: 2em;
  --fs__h1: 3em;
  --fs__h2: 3em;
  --fs__h3: 1.5em;
}

html {
  scroll-behavior: smooth;
  width: 100vw;
}

/* *********** */
/* Fonts, background, general parent-element sizing */
/* *********** */

h1 {
  font-size: 5.58em;
  color: black;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  line-height: 0.83em;
  text-align: start;
  letter-spacing: -0.1em;
}

.body {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-areas: "left right";
}

.rightContainer {
  grid-area: right;
  height: 100%;
  width: 100%;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.image-grid {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(25em, 1fr));
}
