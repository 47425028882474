.videoContainer {
  display: flex;
}

.videoBrowser {
  height: 60vh;
}

.iframeBrowser {
  width: 60vw;
  height: 60vh;
}

.imgBrowser {
  height: 60vh;
  cursor: auto;
}

.videoMobile {
  width: 60vw;
}

.iframeMobile {
  width: 100%;
  margin-top: 4em;
}

.projecth1Browser {
  margin-top: 0.2em;
}

.projecth1Mobile {
  font-size: 3em;
  letter-spacing: -0.075em;
}

.projectPContainerBrowser {
  margin-top: 2em;
  padding-bottom: 1em;
}

.projectPContainerMobile {
  margin-top: 1em;
}

.projectLink {
  text-decoration: underline;
}
