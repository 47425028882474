.leftContainer {
  grid-area: left;
  height: 100%;
  width: 100%;
}

.h1Container {
  height: 100%;
  width: 6em;
  position: fixed;
  background-color: white;
  left: 0;
  top: -2px;
  overflow-y: scroll;
  scrollbar-width: none;
  cursor: pointer;
}

.info {
  height: 100vh;
  width: auto;
  margin-left: 6em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
}

.info p {
  margin-left: -3em;
  margin-top: 5em;
  transform: rotate(-90deg);
}

a {
  text-decoration: none;
  color: black;
}

a :hover,
a :visited {
  color: rgb(226, 184, 232);
}
