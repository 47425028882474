.activeImg {
  visibility: visible;
  height: 100%;
  background-color: rgba(0, 255, 255, 0.549);
  position: fixed;
  display: flex;
  flex-direction: column;
}

.nonActiveImg {
  display: none;
  position: absolute;
}

.imagesWrapper {
  width: 70vw;
  height: 100em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: scroll;
  margin-bottom: -3em;
}

.imagesWrapper > p {
  font-family: var(--ff__bread01);
}

.singleImgWrapper {
  height: fit-content;
  width: 60vw;
}

.singleImgWrapper img {
  max-height: 80vh;
  width: 60vw;
  object-fit: contain;
}

.singleImgTextWrapper {
  text-align: center;
  background-color: white;
  height: 50em;
  width: 60vw;
}

.singleImgTextWrapper p {
  margin-top: 0.5em;
}

.singleImgWrapperMobile {
  margin-top: 20vh;
  text-align: center;
  height: auto;
  width: 100vw;
  overflow-wrap: break-word;
  height: 100%;
}

.singleImgTextWrapperMobile {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.802);
  padding-bottom: 5em;
  font-size: small;
  margin-top: -0.5em;
}

.singleImgTextWrapperMobile > h1 {
  width: 70vw;
  font-size: 4em;
  word-break: keep-all;
}

.singleImgTextWrapperMobile > p {
  width: 70vw;
  word-break: keep-all;
  padding-top: 1em;
}
